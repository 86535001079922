
import { defineComponent, reactive, ref, nextTick, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
// import sign from '@/components/sign.vue'
import HeaderTit from '@/components/HeaderTit.vue'
import { getStorage } from '../commons/SessionStorageUtils'
import AxiosAdapter from '../request/AxiosAdapter'
// import { ElMessage } from 'element-plus'
import LinkAge from '@/components/Selection.vue'
import UserType from '@/components/UserType.vue'
import tianHeUserUpload from '@/components/tianHeUserUpload.vue'
import PreView from '../components/preview/PreView'
import { StringObjectType } from '../commons/Types'
import { requestFunc, getRefFunc } from '../service/Linkage'
import { mobileReg, emailReg, IDReg } from '../commons/unit'
import { throwCustomFunction } from '../commons/ErrorHandle'
import signature from './signature.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
export default defineComponent({
  name: 'WestCityConfirm',
  components: { LinkAge, UserType, tianHeUserUpload, HeaderTit, signature },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const signature = ref(null)
    const agentSignature = ref(null)
    const userUpload = ref(null)
    const userType = ref(null)
    const areaName = ref('')
    const signShow = ref(false)
    // const receiverName = ref()
    const signSrc = ref('')
    const signImgStr = ref()
    const caseUserType = ref(0) //受送达类型s
    const userSubType = ref(0) //受送达类型子类型
    const newData =
      new Date().getFullYear() +
      '年' +
      (new Date().getMonth() + 1) +
      '月' +
      new Date().getDate() +
      '日'
    let courtName = ''
    const getUrl = process.env.VUE_APP_BASE_API
    const verifyCode = ref('')
    const dialogVisible = ref(false)
    const urls = reactive({
      frontUrl: '',
      reverseUrl: ''
    })
    const typeOptions = reactive({
      userElectronicType: ['1'],
      agentElectronicType: []
      // eleNoticeType: ['1', '2']
    })
    const areaOptions: StringObjectType<any> = reactive({
      shengList: [],
      shiList: [],
      quList: []
    })
    const agentAreaOptions: StringObjectType<any> = reactive({
      shengList: [],
      shiList: [],
      quList: []
    })
    const signs = reactive([
      {
        text: '当事人或委托诉讼代理人（签名或签章）',
        bindField: signature,
        title: 'signature',
        isHavingImg: false,
        img: ''
      }
    ])
    const showData = ref(0)
    const isElectronicList = ref([
      {
        code: true,
        name: '是'
      },
      {
        code: false,
        name: '否'
      }
    ])
    const identityTypeList = ref([
      {
        code: 1,
        name: '居民身份证'
      },
      {
        code: 2,
        name: '中国公民护照'
      },
      {
        code: 3,
        name: '台湾居民来往大陆通行证'
      },
      {
        code: 4,
        name: '港澳居民来往内地通行证'
      },
      {
        code: 7,
        name: '统一社会信用代码'
      },
      {
        code: 9,
        name: '律师证'
      }
    ])
    const province = ref(0)
    const agentProvince = ref(0)
    // 初始化省
    requestFunc().then((value) => (areaOptions.shengList = value))
    requestFunc().then((value) => (agentAreaOptions.shengList = value))

    let cityResolve
    let districtResolve
    const cityPromise = new Promise<void>((resolve) => (cityResolve = resolve))
    const districtPromise = new Promise<void>(
      (resolve) => (districtResolve = resolve)
    )
    let agentCityResolve
    let agentDistrictResolve
    const agentCityPromise = new Promise<void>(
      (resolve) => (agentCityResolve = resolve)
    )
    const agentDistrictPromise = new Promise<void>(
      (resolve) => (agentDistrictResolve = resolve)
    )

    const city = getRefFunc(areaOptions, 'shiList', province, true, cityResolve)

    const district = getRefFunc(
      areaOptions,
      'quList',
      city,
      false,
      districtResolve
    )

    const agentCity = getRefFunc(
      agentAreaOptions,
      'shiList',
      agentProvince,
      true,
      agentCityResolve
    )

    const agentDistrict = getRefFunc(
      agentAreaOptions,
      'quList',
      agentCity,
      false,
      agentDistrictResolve
    )

    const ruleForm: any = reactive({
      id: '',
      electronicId: '',
      name: '',
      identityType: '' || null,
      identityNumber: '',
      mobilephone: '',
      otherMessageWay: '',
      userName: '',
      isElectronic: false,
      detailAddress: '',
      email: '',
      confirm: true,
      institutionId: '',
      signature: '',
      agentSignature: '',
      province,
      city,
      district,
      agentCity,
      agentProvince,
      agentDistrict,
      userProvinceCode: '',
      userCityCode: '',
      userDistrictCode: '',
      agentProvinceCode: '',
      agentCityCode: '',
      agentDistrictCode: '',
      agentDetailAddress: '',
      postCode: '',
      userIdentityType: 1,
      userIdentityNumber: '',
      userMobilePhone: '',
      userDetailAddress: '',
      userIsElectronic: true,
      hasUserLitigationAccount: 1,
      hasAgentLitigationAccount: null,
      userEmail: '',
      userElectronicType: '1',
      userFaxNumber: '',
      agentName: '',
      agentIdentityType: '',
      agentIdentityNumber: '',
      agentMobilephone: '',
      agentIsElectronic: true,
      agentElectronicType: '',
      remark: '',
      agentEmail: '',
      agentFoxNumber: ''
      // agentName: ''
    })
    // const inputChange = async () => {
    //   if (ruleForm.agentMobilePhone) {
    //     ruleForm.agentIsElectronic = ['1']
    //   } else {
    //     ruleForm.agentIsElectronic = []
    //   }
    // }
    const checked = ref('1')
    const isShow = ref(1)
    const redisKey = ref('')
    //回显代理人数据
    const filter = async (val: any) => {
      const params = { identityNumber: ruleForm.agentIdentityNumber }
      const data: any = await AxiosAdapter.get({
        url: '/confirmAddress/addressAdentDetail',
        data: params
      })
      ruleForm.agentMobilephone = data.agentMobilephone
      ruleForm.agentProvince = data.agentProvinceCode
      requestFunc().then((value) => (agentAreaOptions.shengList = value))
      ruleForm.agentCity = data.agentCityCode
      agentCityPromise.then(() => (agentCity.value = data.agentCityCode))
      ruleForm.agentDistrict = data.agentDistrictCode
      agentDistrictPromise.then(
        () => (agentDistrict.value = data.agentDistrictCode)
      )
      ruleForm.agentDetailAddress = data.agentDetailAddress
      ruleForm.agentEmail = data.agentEmail
      ruleForm.agentFoxNumber = data.agentFoxNumber
      typeOptions.agentElectronicType = data.agentElectronicType.split(',')
    }
    // const userElectronicType = reactive([])
    // const agentElectronicType = reactive([])
    //调用保存按钮
    const phoneEnter = async (ruleForm: any) => {
      const data: any = await AxiosAdapter.post({
        url: '/webElectronic/electronicSaveAddressChangPing',
        data: ruleForm
      })
      router.push({
        path: '/documents-detail',
        query: {
          id: ruleForm.electronicId,
          caseNumberWord: route.query.caseNumberWord
        }
      })
    }
    if (route.query && typeof route.query.courtName === 'string') {
      courtName = route.query.courtName
    }

    const submit = async () => {
      if (!ruleForm.userName) {
        throwCustomFunction('请输入指定签收人姓名')
      }
      if (ruleForm.userIdentityType == 1) {
        if (!IDReg.test(ruleForm.userIdentityNumber)) {
          throwCustomFunction('请输入正确的身份证号')
        }
      }
      if (ruleForm.userIdentityType == 7) {
        if (ruleForm.userIdentityNumber) {
          const regex = /^[0-9]{18}$|^[0-9]{17}[0-9Xx]$/
          if (!regex.test(ruleForm.userIdentityNumber)) {
            throwCustomFunction('请输入正确的统一社会信用代码')
          }
        }
      }
      if (!ruleForm.userIdentityNumber) {
        throwCustomFunction('请输入证件号')
      }
      if (!mobileReg.test(ruleForm.userMobilePhone)) {
        throwCustomFunction('请输入正确的手机号')
      }

      // if (ruleForm.otherMessageWay) {
      //   if (!mobileReg.test(ruleForm.otherMessageWay)) {
      //     throwCustomFunction('请输入正确的其他联系方式')
      //   }
      // }

      if (!ruleForm.userDetailAddress) {
        throwCustomFunction('请输入详细地址')
      }
      let userArr: any[] = []
      userArr = typeOptions.userElectronicType
      if (
        ruleForm.userIsElectronic &&
        typeOptions.userElectronicType.length > 1
      ) {
        // if (typeOptions.userElectronicType.length < 1) {
        //   throwCustomFunction('同意电子送达后请选择具体送达方式')
        // }
        if (userArr && userArr.indexOf('2') > -1) {
          if (!ruleForm.userEmail) {
            throwCustomFunction('请输入微信号')
          }
        }
        if (userArr && userArr.indexOf('3') > -1) {
          if (!ruleForm.userEmail) {
            throwCustomFunction('请输入QQ号码')
          }
        }
        if (userArr && userArr.indexOf('4') > -1) {
          if (!ruleForm.userEmail) {
            throwCustomFunction('请输入电子邮箱')
          }
        }
        if (userArr && userArr.indexOf('5') > -1) {
          if (!ruleForm.userEmail) {
            throwCustomFunction('请输入传真')
          }
        }
        if (userArr && userArr.indexOf('6') > -1) {
          if (!ruleForm.userEmail) {
            throwCustomFunction('请输入其他联系方式')
          }
        }
        if (ruleForm.userEmail) {
          if (!emailReg.test(ruleForm.userEmail)) {
            throwCustomFunction('请输入正确电子邮件地址')
          }
        }
      }

      // 代理人
      if (caseUserType.value === 1) {
        if (!(province.value && city.value && district.value)) {
          throwCustomFunction('请选择所在地区')
        }
      }
      if (caseUserType.value === 2) {
        if (ruleForm.agentIdentityNumber) {
          const regex = /^\d{17}$/
          if (!regex.test(ruleForm.agentIdentityNumber)) {
            throwCustomFunction('请输入正确的律师执业证号码')
          }
        }
        // if (ruleForm.agentIsElectronic == false && !ruleForm.reason) {
        //   throwCustomFunction('请输入不同意理由')
        // }
      }
      //   if (!ruleForm.agentName) {
      //     throwCustomFunction('请输入代理人名称')
      //   }
      //   if (!(agentProvince.value && agentCity.value && agentDistrict.value)) {
      //     throwCustomFunction('请选择所在地区')
      //   }
      //   if (!ruleForm.agentIdentityType) {
      //     throwCustomFunction('请选择代理人证件类型')
      //   }
      //   if (
      //     ruleForm.agentIdentityType == 1 &&
      //     !IDReg.test(ruleForm.agentIdentityNumber)
      //   ) {
      //     throwCustomFunction('请输入代理人正确的身份证号')
      //   }
      //   if (!ruleForm.agentMobilephone) {
      //     throwCustomFunction('请输入代理人手机号码')
      //   }
      //   if (!ruleForm.agentMobilephone) {
      //     throwCustomFunction('请选择代理人所在地区')
      //   }
      //   if (!(agentProvince.value && agentCity.value && agentDistrict.value)) {
      //     throwCustomFunction('请选择所在地区')
      //   }
      //   if (!ruleForm.agentDetailAddress) {
      //     throwCustomFunction('请输入代理人详细地址')
      //   }

      //   let arr: any[] = []
      //   arr = typeOptions.agentElectronicType
      //   if (ruleForm.agentIsElectronic) {
      //     if (typeOptions.agentElectronicType.length < 1) {
      //       throwCustomFunction('代理人同意电子送达后请选择具体送达方式')
      //     }
      //     if (arr && arr.indexOf('2') > -1) {
      //       if (!ruleForm.agentEmail) {
      //         throwCustomFunction('请输入代理人电子邮件地址')
      //       }
      //     }
      //     if (ruleForm.agentEmail) {
      //       if (!emailReg.test(ruleForm.agentEmail)) {
      //         throwCustomFunction('请输入代理人正确电子邮件地址')
      //       }
      //     }
      //   }
      // }

      if (!ruleForm.confirm) {
        throwCustomFunction('请确认送达内容')
      }
      // if (!route.query.institutionId) {
      //   throwCustomFunction('无法获取当前法院')
      // }
      if (caseUserType.value == 1) {
        ruleForm.agentIsElectronic = false
      }
      ruleForm.institutionId = route.query.institutionId
      ruleForm.redisKey = redisKey.value
      ruleForm.userProvinceCode = ruleForm.province
      ruleForm.userCityCode = ruleForm.city
      ruleForm.userDistrictCode = ruleForm.district
      // ruleForm.agentProvinceCode = ruleForm.agentProvince
      // ruleForm.agentCityCode = ruleForm.agentCity
      // ruleForm.agentDistrictCode = ruleForm.agentDistrict
      ruleForm.userElectronicType = typeOptions.userElectronicType
        .toString()
        .replace(/,/g, '')
      ruleForm.agentElectronicType = typeOptions.agentElectronicType
        .toString()
        .toString()
        .replace(/,/g, '')
      if (!ruleForm.userIsElectronic) {
        ruleForm.userElectronicType = ''
        ruleForm.userFaxNumber = ''
        ruleForm.userEmail = ''
      }
      if (!ruleForm.agentIsElectronic) {
        ruleForm.agentElectronicType = ''
        ruleForm.agentFoxNumber = ''
        ruleForm.agentEmail = ''
      }
      let canSubmit = false
      signs.forEach((current) => {
        if (current.isHavingImg) {
          canSubmit = true
          return
        }
        // const val: any = current.bindField
        // if (val.linen.length > 60) {
        //   canSubmit = true
        if (signImgStr.value) {
          canSubmit = true
          ruleForm[current.title] = signImgStr.value
        }
        // ruleForm[current.title] = val.submit(80, 50)
        // }ruleForm[current.title] = signImgStr.value
      })
      if (!canSubmit) {
        throwCustomFunction('请手写签名')
      }
      if (!ruleForm.confirm) {
        throwCustomFunction('请确认送达内容')
      }
      ruleForm.agentProvinceCode = ruleForm.agentProvince
      ruleForm.agentCityCode = ruleForm.agentCity
      ruleForm.agentDistrictCode = ruleForm.agentDistrict
      if (ruleForm.electronicId == '') {
        // ruleForm.userElectronicType = '1' + ruleForm.userElectronicType
        const data: any = await AxiosAdapter.post({
          url: '/confirmAddress/addAddressForPengJiang',
          data: ruleForm
        })
        if (data) {
          ElMessage.success({
            type: 'success',
            message: '操作成功',
            duration: 2000
          })
          PreView(data, router)
        } else {
          ElMessage.error({
            type: 'error',
            message: data.msg,
            duration: 2000
          })
        }
      } else {
        //没有代理人
        if (route.query.agentName == 'false') {
          if (ruleForm.userIsElectronic == false) {
            console.log('没有代理人,没有勾选同意')
            ElMessageBox.confirm(
              '为了提高文书送达效率，保障当事人诉讼权利，提升审判质效 ，建议您选择电子送达及短信文书送达。电子送达与传统邮寄送达相比，不受时间与空间的限制，随时随地签收 ，既方便又快捷。电子送达与其他送达方式具有同等法律效力。',
              '温馨提示：',
              {
                confirmButtonText: '选择电子送达',
                cancelButtonText: '不选电子送达',
                type: 'warning',
                center: true,
                customClass: 'message-logout',
                showClose: false
              }
            )
              .then(
                (res) => {
                  ruleForm.agentName = ''
                  if (res == 'confirm') {
                    ruleForm.userIsElectronic = true
                    phoneEnter(ruleForm)
                  }
                },
                (data) => {
                  ruleForm.agentName = ''
                  if (data == 'cancel') {
                    ruleForm.userIsElectronic = false
                    phoneEnter(ruleForm)
                  }
                }
              )
              .catch(() => {
                ElMessage({
                  type: 'info',
                  message: '取消选项'
                })
              })
          } else {
            console.log('没有代理人,勾选同意')
            phoneEnter(ruleForm)
          }
        } else {
          if (ruleForm.agentIsElectronic == false) {
            console.log('有代理人,没有勾选同意')
            ElMessageBox.confirm(
              '为了提高文书送达效率，保障当事人诉讼权利，提升审判质效 ，建议您选择电子送达及短信文书送达。电子送达与传统邮寄送达相比，不受时间与空间的限制，随时随地签收 ，既方便又快捷。电子送达与其他送达方式具有同等法律效力。',
              '温馨提示：',
              {
                confirmButtonText: '选择电子送达',
                cancelButtonText: '不选电子送达',
                type: 'warning',
                center: true,
                customClass: 'message-logout',
                showClose: false
              }
            )
              .then(
                (res) => {
                  console.log('仍然同意agentIsElectronic =true', ruleForm)
                  if (res == 'confirm') {
                    ruleForm.agentIsElectronic = true
                    phoneEnter(ruleForm)
                  }
                },
                (data) => {
                  if (data == 'cancel') {
                    ruleForm.agentIsElectronic = false
                    console.log('仍然同意agentIsElectronic =false', ruleForm)
                    phoneEnter(ruleForm)
                  }
                }
              )
              .catch(() => {
                ElMessage({
                  type: 'info',
                  message: '取消选项'
                })
              })
          } else {
            console.log('有代理人,勾选同意')
            phoneEnter(ruleForm)
          }
        }
      }
    }

    const goBack = (type: number) => {
      if (ruleForm.electronicId != '') {
        router.back()
      }
      // router.push({
      //   path: '/west-city-confirmation'
      // })
      // caseUserType.value = 1
      // userSubType.value = 1
      isShow.value = type
    }
    const modifyMessage = () => {
      dialogVisible.value = true
    }
    const cancelGetCode = () => {
      dialogVisible.value = false
      verifyCode.value = ''
    }
    const verifyCodeFun = async (verifyCode?: string) => {
      // const params = {
      //   code: verifyCode,
      //   institutionId: route.query.institutionId
      // }

      const data: any = await AxiosAdapter.post({
        url:
          '/confirmAddress/checkRandomCode?institutionId=' +
          route.query.institutionId +
          '&code=' +
          verifyCode
      })
      ruleForm.id = data.id
      // ruleForm.provinceCode = data.provinceCode
      // ruleForm.cityCode = data.cityCode
      // ruleForm.districtCode = data.districtCode
      ruleForm.institutionId = data.institutionId
      ruleForm.name = data.name
      ruleForm.userIdentityType = data.userIdentityType
      ruleForm.userIdentityNumber = data.userIdentityNumber
      ruleForm.userMobilePhone = data.userMobilePhone
      ruleForm.userDetailAddress = data.userDetailAddress
      ruleForm.userIsElectronic = data.userIsElectronic
      ruleForm.userEmail = data.userEmail
      ruleForm.userFaxNumber = data.userFaxNumber
      ruleForm.agentIdentityType = data.agentIdentityType
      ruleForm.agentIdentityNumber = data.agentIdentityNumber
      ruleForm.agentMobilephone = data.agentMobilephone
      ruleForm.agentDetailAddress = data.agentDetailAddress
      ruleForm.agentIsElectronic = data.agentIsElectronic
      ruleForm.agentElectronicType = data.agentElectronicType
      ruleForm.agentEmail = data.agentEmail
      ruleForm.agentFoxNumber = data.agentFoxNumber
      ruleForm.agentName = data.agentName
      ruleForm.userFaxNumber = data.userFaxNumber
      ruleForm.userElectronicType = data.userElectronicType
      ruleForm.email = data.email
      ruleForm.postCode = data.postCode
      ruleForm.detailAddress = data.detailAddress
      ruleForm.isElectronic = true
      ruleForm.mobilephone = data.mobilephone
      ruleForm.otherMessageWay = data.otherMessageWay
      province.value = data.userProvinceCode
      city.value = data.userCityCode
      district.value = data.userDistrictCode
      agentProvince.value = data.agentProvinceCode
      agentCity.value = data.agentCityCode
      agentDistrict.value = data.agentDistrictCode
      ruleForm.userProvinceCode = data.userProvinceCode
      ruleForm.userCityCode = data.userCityCode
      ruleForm.userDistrictCode = data.userDistrictCode
      ruleForm.agentProvinceCode = data.agentProvinceCode
      ruleForm.agentCityCode = data.agentCityCode
      ruleForm.agentDistrictCode = data.agentDistrictCode
      ruleForm.remark = data.remark
      ruleForm.userName = data.userName
      // ruleForm.signature = data.signature

      cityPromise.then(() => (city.value = data.userCityCode))
      districtPromise.then(() => (district.value = data.userDistrictCode))
      agentCityPromise.then(() => (agentCity.value = data.agentCityCode))
      agentDistrictPromise.then(
        () => (agentDistrict.value = data.agentDistrictCode)
      )
      caseUserType.value = data.userType
      userSubType.value = data.userSubType
      const val: any = userType.value
      if (val) {
        val.userValue = data.userType
        val.proxyValue = data.userSubType
      }
      nextTick(() => {
        const uploadVal: any = userUpload.value
        if (data.userType == 1) {
          if (data.userSubType == 1) {
            uploadVal.identityPhotoUrl = data.identityPhotoUrl || ''
            uploadVal.urlsList.caseUser = data.identityPhotoUrl.split(',')
            uploadVal.urlsList.identityPhotoBackUrl =
              data.identityPhotoBackUrl.split(',')
            uploadVal.companyCertificateUrl = ''
            uploadVal.identityPhotoUrl = ''
            uploadVal.proxyIdentityPhotoUrl = ''
            uploadVal.delegationUrl = ''
            uploadVal.otherUrl = ''
          }
          if (data.userSubType == 2) {
            uploadVal.urlsList.legalIdentity = data.identityPhotoUrl.split(',')
            uploadVal.delegationUrl = data.delegationUrl
            uploadVal.urlsList.delegationUrl = data.delegationUrl.split(',')
            uploadVal.urlsList.hirePaperworkUrl =
              data.hirePaperworkUrl.split(',')

            uploadVal.urlsList.legalWorkIdentity =
              data.companyCertificateUrl.split(',')
            uploadVal.urlsList.identityPhotoBackUrl =
              data.identityPhotoBackUrl.split(',')
            uploadVal.identityPhotoUrl = data.identityPhotoUrl || ''
            uploadVal.companyCertificateUrl = data.companyCertificateUrl || ''
            uploadVal.identityPhotoUrl = ''
            uploadVal.proxyIdentityPhotoUrl = ''
            uploadVal.hirePaperworkUrl = data.hirePaperworkUrl || ''
            uploadVal.otherUrl = ''
          }
        }
        if (data.userType == 2) {
          uploadVal.companyCertificateUrl = ''
          uploadVal.identityPhotoUrl = data.identityPhotoUrl
          uploadVal.identityPhotoBackUrl = data.identityPhotoBackUrl
          uploadVal.proxyIdentityPhotoUrl = data.proxyIdentityPhotoUrl
          uploadVal.proxyIdentityPhotoBackUrl = data.proxyIdentityPhotoBackUrl
          uploadVal.delegationUrl = data.delegationUrl
          uploadVal.otherUrl = data.otherUrl || ''
          uploadVal.urlsList.agentUser = data.identityPhotoUrl.split(',')
          uploadVal.urlsList.agentUserBack =
            data.identityPhotoBackUrl.split(',')
          uploadVal.urlsList.agentProxy = data.proxyIdentityPhotoUrl.split(',')
          uploadVal.urlsList.proxyIdentityPhotoBackUrl =
            data.proxyIdentityPhotoBackUrl.split(',')
          uploadVal.urlsList.agentTrust = data.delegationUrl.split(',')
          uploadVal.urlsList.agentOther = data.otherUrl.split(',')
          uploadVal.getUserList(data.identityPhotoUrl.split(','))
          uploadVal.getUserBackList(data.identityPhotoBackUrl.split(','))
          uploadVal.getProxyList(data.proxyIdentityPhotoUrl.split(','))
          uploadVal.getProxyBackList(data.proxyIdentityPhotoBackUrl.split(','))
          uploadVal.getTrustList(data.delegationUrl.split(','))
          uploadVal.getOtherList(data.otherUrl.split(','))
        }
      })

      if (data.id) {
        ruleForm.signature = ''
      }
      typeOptions.userElectronicType = data.userElectronicType.split(',')
      typeOptions.agentElectronicType = data.agentElectronicType.split(',')

      signs.forEach((item) => {
        const img = data[item.title]
        if (img) {
          item.isHavingImg = true
          item.img = getUrl + img
          signSrc.value = process.env.VUE_APP_BASE_API + data[item.title]
        }
      })
    }
    const confirmGetCode = async () => {
      if (verifyCode.value) {
        await verifyCodeFun(verifyCode.value)
        cancelGetCode()
      }
    }

    const clearSignature = async (current: any) => {
      const val: any = current.bindField
      // const fieldItem = current.bindField
      val.clear()
    }

    const reset = async (current: any) => {
      current.isHavingImg = false
      clearSignature(current)
    }

    const userTypeFun = (user: number, proxy: number) => {
      caseUserType.value = user
      userSubType.value = proxy
    }
    //当事人类型筛选
    const nextUserTypeFun = (obj: any) => {
      caseUserType.value = obj.user.value
      showData.value = obj.user.value
      const detalutEmail1: any = []
      const detalutEmail2: any = []
      if (caseUserType.value == 2) {
        typeOptions.agentElectronicType = detalutEmail1
      } else {
        typeOptions.agentElectronicType = detalutEmail2
      }

      userSubType.value = obj.proxy.value
      isShow.value = 2
    }

    let tranferStatus: number
    //获取当事人信息
    const getConfirmAddressFun = async (id: string) => {
      AxiosAdapter.get<any, any>({
        url: '/webElectronic/getConfirmAddress',
        data: { byElectronicId: id }
      }).then(function (res) {
        const data = res
        ruleForm.userName = data.caseUserName
        ruleForm.userIdentityType = data.identityType
        ruleForm.userIdentityNumber = data.identityNumber
        ruleForm.userMobilePhone = data.mobilephone
        ruleForm.province = data.provinceCode
        cityPromise.then(() => (city.value = data.cityCode))
        ruleForm.city = data.cityCode
        districtPromise.then(() => (district.value = data.districtCode))
        ruleForm.value = data.districtCode
        ruleForm.userDetailAddress = data.detailAddress
      })
    }

    const init = () => {
      if (route.query.agentName == 'true') {
        caseUserType.value = 2
      }
      isShow.value = 3
      ruleForm.agentName = route.query.receiverName
      ruleForm.institutionId = route.query.institutionId
      ruleForm.userName = route.query.userName
      ruleForm.electronicId = route.query.id
      getConfirmAddressFun(ruleForm.electronicId)
    }
    onMounted(async () => {
      if (route.query.agentName) {
        init()
      }
      const res = await AxiosAdapter.get<null, number>({
        url:
          '/confirmAddress/getConfirmVersionConfig?institutionId=' +
          route.query.institutionId
      })
      tranferStatus = res
    })

    const nextUser = (val: any) => {
      if (
        caseUserType.value == 1 &&
        userSubType.value == 2 &&
        tranferStatus == 1
      ) {
        caseUserType.value = 2
        userSubType.value = 5
      }

      isShow.value = 3
      redisKey.value = val
    }

    // 签名
    const signreturnEvent = () => {
      signShow.value = false
    }

    const signEvent = (e: any) => {
      const val: any = signs[0]
      val.isHavingImg = false
      signShow.value = false
      signImgStr.value = e
    }

    const signShowFun = () => {
      signShow.value = true
    }

    return {
      getConfirmAddressFun,
      filter,
      phoneEnter,
      checked,
      init,
      submit,
      goBack,
      modifyMessage,
      userSubType,
      caseUserType,
      showData,
      getStorage,
      getUrl,
      urls,
      agentSignature,
      signature,
      userType,
      courtName,
      signs,
      ruleForm,
      clearSignature,
      reset,
      dialogVisible,
      areaName,
      newData,
      identityTypeList,
      isElectronicList,
      verifyCodeFun,
      confirmGetCode,
      verifyCode,
      cancelGetCode,
      areaOptions,
      agentAreaOptions,
      nextUser,
      typeOptions,
      isShow,
      nextUserTypeFun,
      userTypeFun,
      userUpload,
      signreturnEvent,
      signEvent,
      signShowFun,
      signShow,
      signImgStr,
      signSrc
    }
  }
})
